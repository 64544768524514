import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  //makeStyles,
  Theme,
  Tooltip,
  Button
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { accordianStyles, resultsTabs } from "./GuidlinesComparision";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ObjectType } from "../../masterView/common";
import { Tag } from "../../ui-components/Tag";
import CompensatingFactors from "../../ui-components/CompensatingFactor";
import { getConfig } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { waiverDetails as setWaiverDetails } from "../../stores/WaiverRequest/WaiverRequest.action";
import { GuidlinesComparisionContext } from "./PricingAndGuidlinesSection";
import { GuidlinesComparisionContextForDashboard } from "../../take-out-partners/TKOPModalContainer";
import { RootState } from "../../stores/rootReducer";

export const resultsWithoutWaiver: string[] = [
  "crediteligibility",
  "sectioneligibility",
  "balanceeligibility",
  "overallloaneligibility",
  "criticaltestloaneligibility"
];

export const useStylesResultsMessage = makeStyles((theme: Theme) => ({
  cardWrapper: {
    border: "1px solid",
    padding: "1rem",
    margin: "1rem",
    borderRadius: "5px"
  },
  testNameHeader: {
    display: "flex",
    justifyContent: "space-between"
  },
  testNameWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  testName: {
    fontWeight: 600
  },
  reasonText: {
    margin: "1rem 0"
  },
  currentValue: {
    marginBottom: "1rem"
  },
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
    margin: "0px 10px",
    padding: "0.5rem",
    fontSize: "12px"
  },
  arrow: {
    color: "#fff"
  },
  waiverDataWrapper: {
    borderTop: "1px solid #dedede",
    paddingTop: "1rem"
  }
}));

const tagColor: ObjectType = {
  Requested: "rgb(251, 98, 63)",
  APPROVED: "#2ece89",
  "APPROVED-CONDITIONALLY": "#2ece89",
  REJECTED: "rgb(247, 86, 118)",
  Recind: "rgb(247, 86, 118)"
};

const ResultsCard = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    label,
    name,
    type,
    reason,
    isCriticalTest,
    currentValue,
    waiverDetails = {},
    testVariables,
    loanData
  } = props.resultData;
  const { onRequestWaiverClick, showWaiverButtonProps } = props.showWaiver;
  const {
    userFullName,
    // compensatingFactors,
    dateTime,
    secondaryIdentifierValue,
    identifierValue,
    waiverId,
    takeoutPartnerId,
    loanId,
    loanStage,
    waiverStatus
  } = waiverDetails;

  const { isViewMode } = useSelector<RootState, any>(
    state => state.createLoanStore
  );
  const compensatingFactors =
    waiverDetails?.waiverInfo?.finalCompensatingFactors;
  const classes = useStylesResultsMessage();

  const requestWaiver = () => {
    dispatch(
      setWaiverDetails(
        name,
        reason,
        {
          tab: "LOAN",
          id: "",
          backLocation: window.location.pathname,
          testGroup: type,
          representableTestName: label,
          waiverId: ""
        },
        props.errorAddressList
      )
    );
    if (onRequestWaiverClick) {
      return onRequestWaiverClick({
        name,
        subject: name,
        reason,
        details: {
          tab: "LOAN",
          id: "",
          backLocation: window.location.pathname,
          testGroup: type,
          representableTestName: label,
          waiverId: ""
        },
        errorAddressList: props.errorAddressList
      });
    }

    navigate(
        `/loan/waiver/request/${loanStage}/${loanId}?pId=${takeoutPartnerId}`
    );
  };
  const showWaiverButton = (label: string): boolean => {
    return props.resultStatus === "fail" && showWaiverButtonProps && !waiverId
      ? !resultsWithoutWaiver?.includes(label?.toLowerCase()?.replace(/\s/g, ''))
      : false;
  };
  return (
    <div
      className={classes.cardWrapper}
      style={{ borderColor: isCriticalTest ? "#f7b500" : "#dedede" }}
    >
      <div className={classes.testNameHeader}>
        <div className={classes.testNameWrapper}>
          <span className={classes.testName}>{label}</span>
          {type && <span style={{ marginLeft: "2px" }}>{`(${type})`}</span>}
        </div>
        <div>
          {isCriticalTest && (
            <Tooltip
              title="Critical"
              placement="left"
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow
              }}
              arrow
            >
              <WarningIcon fontSize="small" color="error" />
            </Tooltip>
          )}
          {props.resultStatus === "fail" && !isCriticalTest && (
            <InfoIcon fontSize="small" htmlColor="#f7b500" />
          )}
          {props.resultStatus !== "fail" && (
            <CheckCircleIcon fontSize="small" htmlColor="#2ece89" />
          )}
        </div>
      </div>
      {reason && (
        <div className={classes.reasonText}>
          Failure Reason: <b>{reason}</b>
        </div>
      )}
      {currentValue && (
        <div className={classes.currentValue}>
          <span>Current Value:</span>
          {testVariables?.map((variableObj: ObjectType) => {
            return (
              <span>{`${variableObj.key} : ${variableObj.formatter?.(
                loanData?.[variableObj.fieldPath] ?? ""
              ) ??
                loanData?.[variableObj.fieldPath] ??
                ""}`}</span>
            );
          })}
        </div>
      )}
      {showWaiverButton(label) && !isViewMode && (
        <div>
          <Button
            variant="contained"
            size="small"
            data-testid="request-waiver-btn"
            onClick={requestWaiver}
            color="info"
            // disabled={
            //     isViewMode ||
            //     isWithdrawnLoan ||
            //     (loanStage !== LoanStage.fes &&
            //         (loanState === LoanStatusEnum.DataEntry ||
            //             loanState === LoanStatusEnum.Submit))
            // }
          >
            Request Waiver
          </Button>
        </div>
      )}
      {waiverStatus && (
        <div className={classes.waiverDataWrapper}>
          <div style={{ display: "flex" }}>
            <Tag
              data={{
                label: `Waiver ${waiverStatus}`,
                style: { backgroundColor: tagColor?.[waiverStatus] }
              }}
            />
          </div>
          <CompensatingFactors compensatingFactors={compensatingFactors} />
          {
            <div style={{ margin: "1rem 0" }}>
              {`${waiverStatus} By :`}
              <span>
                <b>{`${userFullName} `}</b>
              </span>
              <span>
                <b>,{` ${dateTime}`}</b>
              </span>
            </div>
          }
          <Button
            variant="contained"
            size="small"
            color="info"
            data-testid="request-waiver-btn"
            onClick={() => {
              window.open(
                `${
                  getConfig().redirectUrl
                }loan/waiver/request/${secondaryIdentifierValue}/${identifierValue}/view/${waiverId}?pId=${takeoutPartnerId}`
              );
            }}
            style={{ marginTop: "5px" }}
          >
            View Details
          </Button>
        </div>
      )}
    </div>
  );
};

const ResultsAccordian = (props: any) => {
  const context1 = useContext(GuidlinesComparisionContext);
  const context2 = useContext(GuidlinesComparisionContextForDashboard);

  const takeOutPartner = context1?.takeOutPartner ?? context2?.takeOutPartner;

  const { partnerData, tabData, programSelectConfig } = props;
  const accordianClasses = accordianStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    if (programSelectConfig.isProgramSelectView && tabData?.label === "Fail") {
      setExpanded(true);
    }
  }, [programSelectConfig.isProgramSelectView, tabData]);
  return (
    <Accordion
      elevation={0}
      onChange={() => setExpanded(!expanded)}
      classes={{
        root: accordianClasses.accordion,
        expanded: accordianClasses.accordionExpanded
      }}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          expanded === true ? (
            <RemoveIcon
              fontSize="small"
              className={accordianClasses.smallAccordionSvg}
              color="primary"
            />
          ) : (
            <AddIcon
              fontSize="small"
              className={accordianClasses.smallAccordionSvg}
              color="primary"
            />
          )
        }
        classes={{ root: accordianClasses.accordionSummary }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {`${tabData?.label} ${
          partnerData?.[tabData.value]?.length
            ? `(${partnerData?.[tabData.value]?.length})`
            : `(0)`
        }`}
      </AccordionSummary>
      <AccordionDetails
        style={{ display: "flex", flexDirection: "column" }}
        classes={{
          root: accordianClasses.accordionDetails
        }}
      >
        {partnerData?.[tabData?.value]?.map((resultData: ObjectType) => {
          return (
            <ResultsCard
              resultData={resultData}
              resultStatus={tabData?.value}
              errorAddressList={partnerData?.errorAddressList}
              showWaiver={{
                showWaiverButtonProps: programSelectConfig.isProgramSelectView
                  ? programSelectConfig?.selectedProgram
                  : partnerData.key === takeOutPartner?.id,
                onRequestWaiverClick: programSelectConfig?.onRequestWaiverClick
              }}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export const ResultsListWithFailMessages = (props: any) => {
  const { partnerData, width, showWaiver, programSelectConfig } = props;
  return (
    <div
      style={{
        border: "1px solid #dedede",
        borderRight: "0px",
        // maxWidth: "calc(33VW - 16px)",
        width: `${width}%`
      }}
    >
      {resultsTabs.map(tabData => {
        return (
          <ResultsAccordian
            key={tabData.value}
            partnerData={partnerData}
            tabData={tabData}
            showWaiver={showWaiver}
            programSelectConfig={programSelectConfig}
          />
        );
      })}
    </div>
  );
};
